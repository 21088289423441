import React, { useState, useEffect } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import api from '@alweb-merkator/shared/services/api';
import { useParams } from 'react-router';

import { Container, Image, Slide, ArrowLeft, ArrowRight } from './styles';

function Slider({ ...props }) {
  const [activeItem, changeActiveItem] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataBanner, setDataBanner] = useState([]);
  const { lang } = useParams();

  const getDataConfig = async () => {
    await api.get(`/blconfiguration`).then((response) => {
      const dataItem = [];
      console.log(response.data._embedded.bl_configuration);
      response.data._embedded.bl_configuration.map((data, idx) => {
        if (data.id === 1) {
          if (lang === 'pt') {
            dataItem.push({
              original: data.big_banner_pt,
              description: data.title,
            });
          }
          if (lang === 'es') {
            dataItem.push({
              original: data.big_banner_es,
              description: data.title,
            });
          }
          if (lang === 'en') {
            dataItem.push({
              original: data.big_banner_en,
              description: data.title,
            });
          }
        }
      });
      setDataBanner(dataItem);
      setIsLoading(true);
    });
  };

  useEffect(() => {
    getDataConfig();
  }, []);
  function getPrevItem() {
    let prevItem = activeItem - 1;

    if (prevItem < 0) {
      prevItem = dataBanner.length - 1;
    }

    return prevItem;
  }

  function getNextItem() {
    let nextItem = activeItem + 1;

    if (nextItem < 0 || nextItem >= dataBanner.length) {
      nextItem = 0;
    }

    return nextItem;
  }

  return (
    <Container {...props}>
      {isLoading &&
        dataBanner.map((data, idx) => {
          return (
            <Slide
              className={`${activeItem === idx ? 'active' : ''}`}
              key={idx}
            >
              <Image
                src={`https://paineldoexpositor.com.br/uploads/configuration/${data.original}`}
                alt={data.description}
              />
            </Slide>
          );
        })}
      {dataBanner.length > 1 && (
        <>
          <ArrowLeft onClick={() => changeActiveItem(getPrevItem())}>
            <FaAngleLeft />
          </ArrowLeft>

          <ArrowRight onClick={() => changeActiveItem(getNextItem())}>
            <FaAngleRight />
          </ArrowRight>
        </>
      )}
    </Container>
  );
}

export default Slider;
