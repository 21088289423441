import React from 'react';

import SecaoImprensa from '@alweb-merkator/shared/pages/PaginaInicial/SecaoImprensaMerkator';
import SecaoEMerkator from '@alweb-merkator/shared/pages/PaginaInicial/SecaoEMerkator';

import SecaoBanner from './SecaoBanner';
import SecaoQuemSomos from './SecaoQuemSomos';
import SecaoProdutos from './SecaoProdutos';
import SecaoContato from './SecaoContato';
import AlertModal from '@alweb-merkator/shared/components/AlertModal';

const PaginaInicial = () => (
  <>
    <SecaoBanner />
    <SecaoQuemSomos />
    <SecaoProdutos />
    <SecaoEMerkator />
    <SecaoImprensa />
    <SecaoContato />
    {
      <AlertModal
        imageDescription="Assim como todo ano vai ter SICC!"
        urlImage="https://www.sicc.com.br/"
        urlTitle="As feiras da Merkator têm datas marcadas"
        imageModal="http://paineldoexpositor.com.br/uploads/media/media_67631986e92291_83731284.jpeg"
        showModal={false}
      />
    }
  </>
);

export default PaginaInicial;
